import * as React from 'react';

export default ({
  width = 126,
  height = 28,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 126 28'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g fill='currentColor'>
        <path d='m50.006 3.21205h-7.6195c-.1312 0-.2443.08486-.2855.20315l-.0205.09514-2.2476 12.38966h-.0617l-2.2501-12.38966c0-.03343-.0077-.06428-.018-.09514-.0411-.11572-.1517-.20315-.2854-.20315h-7.6169c-.1697 0-.3034.13114-.3034.29829v20.81656c0 .1646.1337.2983.3034.2983h4.7316c.1672 0 .3035-.1311.3035-.2983v-15.65805h.0617l2.9984 15.71205v.0129c.0334.1337.1517.2314.2983.2314h3.6156c.1363 0 .2494-.0874.2906-.2057l.0128-.0771 2.9907-15.67612h.0617v15.65812c0 .1645.1389.2983.3061.2983h4.7316c.1671 0 .3034-.1312.3034-.2983v-20.81663c0-.16715-.1363-.2983-.3034-.2983' />
        <path d='m93.1514 3.21205h-5.1045c-.1672 0-.3035.13114-.3035.29829v15.15406c0 1.0415-.1543 1.7332-.4603 2.083-.3086.3497-.7277.5246-1.2575.5246-.5297 0-.9514-.1749-1.2549-.5246-.3086-.3472-.4629-1.0415-.4629-2.083v-15.15663c0-.16715-.1337-.2983-.3034-.2983h-5.1071c-.1671 0-.306.13115-.306.2983v13.74743c0 1.4992.1646 2.7516.4912 3.7493.3266 1.0003.8023 1.8027 1.4297 2.4018.6224.5992 1.4015 1.0106 2.335 1.2292.9309.2212 1.9878.3292 3.1784.3292s2.2733-.108 3.1939-.3292c.9206-.2186 1.692-.63 2.3169-1.2292.6249-.5991 1.1007-1.4015 1.4272-2.4018.3292-.9977.4938-2.2501.4938-3.7493v-13.74743c0-.16715-.1389-.2983-.306-.2983' />
        <path d='m73.8645 13.3205c-.6969-.5015-1.4478-.9052-2.2476-1.2164-.7997-.3111-1.5532-.6171-2.2475-.918-.6994-.2983-1.278-.6532-1.7409-1.0646-.4629-.41146-.6918-.96434-.6918-1.66637 0-.48088.1415-.91547.4346-1.30634.288-.39087.7406-.58374 1.3604-.58374.558 0 .9669.22373 1.2369.67632.27.45002.4011.99261.4011 1.63292v.21344c0 .16458.1363.2983.3061.2983h4.729c.1646 0 .2957-.12601.3034-.28544v-.02829c-.0488-2.13695-.6068-3.70816-1.6714-4.70848-1.1187-1.04919-2.929-1.57378-5.4286-1.57378-2.4995 0-4.225.57088-5.413 1.71007-1.1907 1.13919-1.7847 2.8004-1.7847 4.97849 0 1.2626.2186 2.2809.6609 3.0601.4449.7792.9977 1.4092 1.6638 1.8901.6686.4809 1.3912.8692 2.1704 1.17.7817.2983 1.5043.5941 2.1703.8847.6686.2905 1.2241.6403 1.6638 1.0491.4423.4089.6635.9644.6635 1.6638 0 .5812-.1595 1.0724-.4783 1.4709-.3215.3986-.7766.5992-1.3732.5992-.5555 0-1.0081-.1826-1.3552-.5529-.3498-.3677-.5246-1.0132-.5246-1.9338v-.2134c0-.1646-.1363-.2983-.3035-.2983h-4.9193c-.1672 0-.306.1337-.306.2983v.4526c0 1.1212.1645 2.0598.4911 2.8235.3266.7586.8049 1.3732 1.4298 1.8438s1.3938.8023 2.3067.9926c.9103.1903 1.9621.2855 3.1527.2855 2.5201 0 4.4487-.5143 5.7808-1.5455 1.332-1.0312 2.0006-2.6873 2.0006-4.9682 0-1.3012-.2314-2.3556-.6943-3.1682-.4628-.81-1.044-1.4657-1.7409-1.9646' />
        <path d='m58.3791 3.21205h-4.9193c-.1672 0-.3036.13114-.3036.29829v4.80364c0 .16458.1364.2983.3036.2983h4.9193c.1698 0 .3061-.13115.3061-.2983v-4.80621c0-.16715-.1363-.2983-.3061-.2983' />
        <path d='m58.3791 10.5098h-4.9193c-.1672 0-.3036.1337-.3036.2957v13.5211c0 .1646.1364.2983.3036.2983h4.9193c.1698 0 .3061-.1311.3061-.2983v-13.5185c0-.162-.1363-.2958-.3061-.2958' />
        <path d='m117.087 3.21205h-7.619c-.131 0-.245.08486-.286.20315l-.018.09514-2.252 12.38966h-.06l-2.252-12.38966c0-.03343-.005-.06428-.016-.09514-.041-.11572-.151-.20315-.285-.20315h-7.617c-.1697 0-.306.13114-.306.29829v20.81656c0 .1646.1363.2983.306.2983h4.732c.169 0 .303-.1311.303-.2983v-15.65805h.062l2.998 15.71205v.0129c.034.1337.154.2314.298.2314h3.621c.137 0 .247-.0874.286-.2057l.015-.0771 2.991-15.67612h.061v15.65812c0 .1645.137.2983.304.2983h4.734c.167 0 .304-.1312.304-.2983v-20.81663c0-.16715-.137-.2983-.304-.2983' />
        <path d='m125.458 3.21205h-4.92c-.167 0-.303.13114-.303.29829v4.80364c0 .16458.136.2983.303.2983h4.92c.167 0 .303-.13115.303-.2983v-4.80621c0-.16715-.139-.2983-.303-.2983' />
        <path d='m125.458 10.5098h-4.92c-.167 0-.303.1337-.303.2957v13.5211c0 .1646.136.2983.303.2983h4.92c.167 0 .303-.1311.303-.2983v-13.5185c0-.162-.139-.2958-.303-.2958' />
        <path
          clipRule='evenodd'
          d='m14.9303 23.3033-5.96078 4.5362-8.96952-7.3418v-10.66157l2.47382-1.79236 8.10288 3.04473v-9.19071l2.4739-1.89779 8.9695 7.35974.0025 10.57676-6.0636 4.6416-1.0287-1.8952zm-1.8566-22.395585-1.6689 1.278055 4.7419 9.07243 5.0094-3.71078zm-12.086395 9.113385 1.609785-1.23692 11.25821 4.20442-4.88594 3.7133zm-.244141.8026v9.4041l7.791756 6.434v-9.4041zm13.423336 2.8492-4.92968 3.7107v9.3603l4.92968-3.7313zm1.4838-1.7949-4.3305-8.2906v7.7326l3.5873 1.3629v6.3311l.7432 1.4427zm5.6293-3.58991v9.34491l-4.8885 3.7725v-9.4041z'
          fillRule='evenodd'
        />
      </g>
    </svg>
  );
};
