import * as React from 'react';

import cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { getQueryParams } from '~/components/shared/Link';

import DecoupledSignupCTA from './DecoupledSignupCTA';
import LegacyAuthButtons from './LegacyAuthButtons';

interface AuthButtonProps {
  titleBtns: string;
  primaryBtn: string;
  secondaryBtn: string;
  primaryBtnDropdown1: string;
  primaryBtnDropdown2: string;
  gitlabBtn?: string;
  signupBtn: string;
  'data-cy'?: string;
  page: 'signup' | 'vcsAuthorized';
}

const AuthButtons = ({
  titleBtns,
  primaryBtn,
  secondaryBtn,
  primaryBtnDropdown1,
  primaryBtnDropdown2,
  gitlabBtn,
  signupBtn,
  page,
  'data-cy': dataCy,
}: AuthButtonProps): React.ReactElement => {
  const router = useRouter();
  const queryParams = getQueryParams(router ? router.asPath : '');
  const returnTo = queryParams.get('return-to');
  const query = new Map();
  if (returnTo) {
    query.set('return-to', returnTo);
  }

  if (returnTo?.includes('locked_project')) {
    // TODO: this should be changed into localStorage
    cookies.set('activation-lockedProjectCampaignReturnToUrl', returnTo, {
      domain: '.circleci.com',
      path: '/',
    });
  }

  if (page === 'signup') {
    return (
      <DecoupledSignupCTA
        titleBtns={titleBtns}
        signupBtn={signupBtn}
        secondaryBtn={secondaryBtn}
        page={page}
        query={query}
      />
    );
  }

  return (
    <LegacyAuthButtons
      data-cy={dataCy}
      primaryBtn={primaryBtn}
      secondaryBtn={secondaryBtn}
      primaryBtnDropdown1={primaryBtnDropdown1}
      primaryBtnDropdown2={primaryBtnDropdown2}
      gitlabBtn={gitlabBtn}
      page={page}
    />
  );
};

export default AuthButtons;
