import * as React from 'react';

export default ({
  width = 57,
  height = 22,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      enableBackground='new 0 0 110 38.6'
      viewBox='0 0 110 38.6'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='currentColor'>
        <path d='m38 .1h-5.7c-.9 0-1.6.7-1.6 1.6v35.2c0 .9.7 1.6 1.6 1.6h5.7c.9 0 1.6-.7 1.6-1.6v-35.2c0-.9-.7-1.6-1.6-1.6' />
        <path d='m77 .1h-5.7c-.9 0-1.6.7-1.6 1.6v20.9l-16.1-21.8c0-.1-.1-.1-.1-.2s-.1-.1-.1-.1-.1 0-.1-.1-.1 0-.1-.1h-.1-.1s0 0-.1 0h-.1s0 0-.1 0h-.1s0 0-.1 0h-.1-.1s0 0-.1 0h-5.7c-.9 0-1.6.7-1.6 1.6v35.2c0 .9.7 1.6 1.6 1.6h5.7c.9 0 1.6-.7 1.6-1.6v-21.1l16.2 21.8c.1.2.2.3.4.4s.1 0 .1.1h.1.1.1c.1 0 .3.1.4.1h5.8c.9 0 1.6-.7 1.6-1.6v-35.1c-.1-.9-.8-1.6-1.6-1.6' />
        <path d='m24.4 29.6h-15.4v-27.9c0-.9-.7-1.6-1.6-1.6h-5.7c-.9 0-1.6.7-1.6 1.6v35.2c0 .4.2.8.4 1.1.3.3.7.4 1.1.4h22.6c.9 0 1.6-.7 1.6-1.6v-5.7c.1-.8-.6-1.5-1.4-1.5' />
        <path d='m108.2 8.9c.9 0 1.6-.7 1.6-1.6v-5.6c0-.9-.7-1.6-1.6-1.6h-22.6c-.4 0-.8.2-1.1.4-.3.4-.5.8-.5 1.2v35.2c0 .4.2.8.4 1.1.3.3.7.4 1.1.4h22.6c.9 0 1.6-.7 1.6-1.6v-5.7c0-.9-.7-1.6-1.6-1.6h-15.3v-5.9h15.4c.9 0 1.6-.7 1.6-1.6v-5.7c0-.9-.7-1.6-1.6-1.6h-15.4v-5.8z' />
      </g>
    </svg>
  );
};
