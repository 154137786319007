import * as React from 'react';

export default ({
  width = 140,
  height = 26,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 1000 198'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        fillRule='evenodd'
        clipRule='evenodd'
        fill='currentColor'
      >
        <path d='M974.795 150.85C975.4 157.744 980.914 166.518 990.467 166.518H996.057C998.223 166.518 1000 164.742 1000 162.574V39.6459H999.975C999.862 37.575 998.151 35.9163 996.057 35.9163H978.737C976.643 35.9163 974.932 37.575 974.817 39.6459H974.795V49.6345C964.184 36.5542 947.453 31.125 930.676 31.125C892.539 31.125 861.625 62.0634 861.625 100.23C861.625 138.399 892.539 169.338 930.676 169.338V169.342C947.453 169.342 965.736 162.828 974.792 150.834L974.795 150.85V150.85ZM930.743 145.152C906.489 145.152 886.825 125.042 886.825 100.23C886.825 75.4229 906.489 55.3114 930.743 55.3114C954.996 55.3114 974.657 75.4229 974.657 100.23C974.657 125.042 954.996 145.152 930.743 145.152V145.152Z' />
        <path d='M839.729 155.078L839.721 91.4679H839.723C839.723 55.6113 817.119 31.4219 781.075 31.4219C763.869 31.4219 749.764 41.379 744.783 49.9324C743.706 43.2443 740.158 36.2142 729.155 36.2142H723.547C721.378 36.2142 719.606 37.9914 719.606 40.1599V151.158C719.606 151.161 719.606 151.17 719.606 151.175V163.098H719.628C719.742 165.166 721.455 166.827 723.549 166.827H740.869C741.134 166.827 741.394 166.795 741.645 166.746C741.76 166.724 741.867 166.679 741.978 166.647C742.107 166.608 742.241 166.577 742.363 166.527C742.518 166.463 742.657 166.377 742.801 166.297C742.871 166.254 742.944 166.224 743.012 166.178C743.185 166.067 743.344 165.933 743.497 165.796C743.522 165.77 743.552 165.754 743.577 165.73C743.749 165.564 743.906 165.38 744.046 165.189C744.047 165.186 744.047 165.186 744.047 165.186C744.481 164.59 744.744 163.872 744.787 163.098H744.809V90.4817C744.809 71.2242 760.41 55.6113 779.655 55.6113C798.898 55.6113 814.499 71.2242 814.499 90.4817L814.515 151.179L814.519 151.158C814.519 151.212 814.528 151.267 814.528 151.325V163.098H814.551C814.665 165.166 816.376 166.827 818.47 166.827H835.791C836.057 166.827 836.316 166.795 836.567 166.746C836.669 166.727 836.764 166.686 836.864 166.658C837.006 166.617 837.151 166.581 837.288 166.527C837.429 166.468 837.558 166.386 837.692 166.311C837.774 166.264 837.86 166.231 837.939 166.178C838.098 166.074 838.245 165.947 838.388 165.821C838.425 165.787 838.466 165.762 838.501 165.727C838.665 165.569 838.811 165.398 838.945 165.219C838.954 165.205 838.965 165.194 838.975 165.181C839.402 164.59 839.663 163.878 839.708 163.109C839.708 163.103 839.71 163.098 839.71 163.098H839.733V155.08L839.729 155.078' />
        <path d='M659.351 150.85C659.957 157.744 665.471 166.518 675.023 166.518H680.613C682.78 166.518 684.553 164.742 684.553 162.574V39.6459H684.531C684.418 37.575 682.705 35.9163 680.613 35.9163H663.293C661.199 35.9163 659.488 37.575 659.371 39.6459H659.351V49.6345C648.738 36.5542 632.008 31.125 615.23 31.125C577.095 31.125 546.181 62.0634 546.181 100.23C546.181 138.399 577.095 169.338 615.23 169.338V169.342C632.008 169.342 650.293 162.828 659.349 150.834L659.351 150.85V150.85ZM615.297 145.152C591.045 145.152 571.382 125.042 571.382 100.23C571.382 75.4229 591.045 55.3114 615.297 55.3114C639.552 55.3114 659.211 75.4229 659.211 100.23C659.211 125.042 639.552 145.152 615.297 145.152V145.152Z' />
        <path d='M443.756 133.244C455.318 141.26 467.939 145.153 480.067 145.153C491.621 145.153 503.566 139.159 503.566 128.725C503.566 114.795 477.538 112.628 461.184 107.065C444.828 101.503 430.741 90.0046 430.741 71.3838C430.741 42.891 456.109 31.123 479.786 31.123C494.788 31.123 510.267 36.0738 520.302 43.1658C523.758 45.7865 521.653 48.7954 521.653 48.7954L512.074 62.4874C510.997 64.0295 509.115 65.3619 506.412 63.6928C503.71 62.0247 494.231 55.3104 479.786 55.3104C465.34 55.3104 456.638 61.9869 456.638 70.2623C456.638 80.1859 467.948 83.307 481.195 86.6894C504.28 92.9169 529.463 100.402 529.463 128.725C529.463 153.829 505.999 169.344 480.067 169.344C460.415 169.344 443.684 163.737 429.651 153.432C426.728 150.504 428.77 147.786 428.77 147.786L438.302 134.163C440.241 131.616 442.687 132.504 443.756 133.244' />
        <path d='M377.173 150.85C377.779 157.744 383.293 166.518 392.846 166.518H398.436C400.602 166.518 402.378 164.742 402.378 162.574V39.6459H402.354C402.241 37.575 400.53 35.9163 398.436 35.9163H381.116C379.022 35.9163 377.311 37.575 377.194 39.6459H377.173V49.6345C366.562 36.5542 349.831 31.125 333.054 31.125C294.919 31.125 264.004 62.0634 264.004 100.23C264.004 138.399 294.919 169.338 333.054 169.338V169.342C349.831 169.342 368.115 162.828 377.171 150.834L377.173 150.85V150.85ZM333.122 145.152C308.868 145.152 289.205 125.042 289.205 100.23C289.205 75.4229 308.868 55.3114 333.122 55.3114C357.375 55.3114 377.035 75.4229 377.035 100.23C377.035 125.042 357.375 145.152 333.122 145.152V145.152Z' />
        <path d='M167.197 104.931C141.533 104.931 120.729 125.736 120.729 151.401C120.729 177.065 141.533 197.869 167.197 197.869C192.861 197.869 213.665 177.065 213.665 151.401C213.665 125.736 192.861 104.931 167.197 104.931V104.931ZM46.4683 104.935C20.8048 104.935 0 125.736 0 151.401C0 177.065 20.8048 197.87 46.4683 197.87C72.1329 197.87 92.9387 177.065 92.9387 151.401C92.9387 125.736 72.1329 104.935 46.4683 104.935V104.935ZM153.3 46.8442C153.3 72.5099 132.496 93.3167 106.833 93.3167C81.1681 93.3167 60.3643 72.5099 60.3643 46.8442C60.3643 21.1838 81.1681 0.376953 106.833 0.376953C132.496 0.376953 153.3 21.1838 153.3 46.8442V46.8442Z' />
      </g>
    </svg>
  );
};
