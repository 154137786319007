import * as React from 'react';

import { useTrackAction } from '@circleci/analytics';
import { PrimaryButtonLink } from '@circleci/compass';
import { IconComponent } from '@circleci/compass/dist/types';

import linkMessages from '~/links/messages';

import { trackButtonAction, UseTranslatedHref } from '../shared/Link';
import ButtonContents from './ButtonContents';

interface EmailButtonProps {
  textId: string;
  query: Map<string, string>;
  page: 'signup' | 'vcsAuthorized';
  Icon: IconComponent;
}

const AuthButtonEmail = ({ textId, query, page, Icon }: EmailButtonProps) => {
  // Conditional URL for login vs sign up pages. GH and BB authentication
  // uses the same URL for login and sign up which is why this is only needed
  // for auth0 auth.
  const authURL =
    page === 'vcsAuthorized' ? linkMessages.authLogin : linkMessages.authSignUp;
  const trackLink = useTrackAction({
    context: 'authentication',
    object: 'button',
    eventProperties: {
      button: 'email_pw',
    },
  });
  return (
    <PrimaryButtonLink
      className='email-btn'
      data-testid='email-btn'
      data-optimizely='email-button'
      href={UseTranslatedHref(authURL, undefined, query)}
      onPress={trackButtonAction(
        UseTranslatedHref(authURL, undefined, query),
        trackLink,
      )}
    >
      <ButtonContents
        Icon={Icon}
        textId={textId}
      />
    </PrimaryButtonLink>
  );
};

export default AuthButtonEmail;
