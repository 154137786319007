import * as React from 'react';

export default ({
  width = 140,
  height = 32,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 120 28'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='currentColor'>
        <path d='m40.8185 21.7136v-6.4305h-5.662v6.4305h-2.8888v-15.28318h2.8888v6.28628h5.662v-6.25744h2.8888v15.28324h-2.8888z' />
        <path d='m54.2801 21.7137h-2.2821l-.2022-.7497c-.9822.6344-2.1377.9804-3.3221.9804-2.051 0-2.9177-1.3841-2.9177-3.3162 0-2.278.9822-3.1431 3.2644-3.1431h2.6865v-1.1823c0-1.24-.3466-1.6725-2.1377-1.6725-1.0399 0-2.0799.1153-3.091.3172l-.3466-2.1339c1.2421-.346 2.5421-.519 3.8421-.519 3.5243 0 4.5353 1.2399 4.5353 4.037zm-2.8021-4.2101h-2.051c-.9244 0-1.1844.2595-1.1844 1.0958 0 .7786.26 1.1246 1.1266 1.1246.7511 0 1.4733-.2018 2.1088-.5767z' />
        <path d='m59.7112 21.9444c-1.3-.0289-2.5999-.2307-3.871-.6344l.4044-2.1339c1.0978.3172 2.1955.4614 3.3221.4902 1.2422 0 1.4155-.2883 1.4155-1.1246 0-.6921-.1444-1.0381-1.9643-1.4706-2.7444-.6633-3.0621-1.3554-3.0621-3.5181 0-2.2492.9821-3.2296 4.1887-3.2296 1.1266 0 2.2532.1441 3.351.3748l-.2889 2.2204c-1.0111-.173-2.051-.2883-3.0621-.3172-1.2133 0-1.4155.2884-1.4155.9516 0 .894.0578.9516 1.5888 1.3553 3.1199.8363 3.4377 1.24 3.4377 3.5181 0 2.1915-.6645 3.518-4.0443 3.518z' />
        <path d='m72.104 21.7137v-7.7858c0-.6055-.26-.8939-.8956-.8939-.6933 0-1.9066.4037-2.9176.9516v7.7281h-2.8022v-15.71575l2.8022-.40371v5.79606c1.271-.6632 2.6577-1.0092 4.0732-1.0669 1.8488 0 2.5132 1.2976 2.5132 3.3162v8.1029h-2.7732z' />
        <path d='m76.9572 8.96819v-3.22966h2.8021v3.22966zm0 12.74561v-11.1596h2.8021v11.1596z' />
        <path d='m81.7236 10.6406c0-2.79713 1.6178-4.41196 5.4309-4.41196 1.3867 0 2.8022.17301 4.1599.51905l-.3178 2.47992c-1.2422-.23069-2.4843-.37487-3.7554-.40371-1.9933 0-2.6288.69207-2.6288 2.3069v5.8826c0 1.6148.6355 2.3069 2.6288 2.3069 1.2711-.0288 2.5132-.1442 3.7554-.3749l.3178 2.5088c-1.3577.346-2.7444.519-4.1599.519-3.8131 0-5.4309-1.6148-5.4309-4.4119z' />
        <path d='m97.0631 21.9444c-3.8421 0-4.8821-2.105-4.8821-4.4119v-2.826c0-2.3069 1.04-4.4119 4.8821-4.4119 3.8419 0 4.8819 2.105 4.8819 4.4119v2.826c-.029 2.3069-1.069 4.4119-4.8819 4.4119zm0-9.2276c-1.5022 0-2.0511.6632-2.0511 1.932v2.999c0 1.2688.5778 1.932 2.0511 1.932s2.051-.6632 2.051-1.932v-2.999c0-1.2688-.5777-1.932-2.051-1.932z' />
        <path d='m109.369 12.8898c-1.04.4614-2.022 1.0093-2.975 1.6148v7.2091h-2.802v-11.1596h2.368l.174 1.2399c.924-.6055 1.906-1.0958 2.946-1.4706z' />
        <path d='m120 18.0228c0 2.4799-1.098 3.9505-3.698 3.9505-1.011 0-1.993-.1153-2.975-.3172v4.6138l-2.802.3749v-16.0906h2.224l.289.9516c1.011-.7498 2.253-1.1823 3.524-1.1535 2.254 0 3.438 1.3265 3.438 3.8641zm-6.702 1.2399c.809.173 1.647.2884 2.484.2884 1.011 0 1.416-.4902 1.416-1.4995v-3.9217c0-.9228-.347-1.413-1.387-1.413-.924.0288-1.82.4037-2.513 1.0092z' />
        <path d='m11.0351 0-11.0351 6.34398v.02883 15.25439l4.15985 2.3934v-15.28322l6.87525-3.95056z' />
        <path d='m15.455 0v12.1689h-4.4199v-4.55613l-4.13093 2.39343v15.6004l4.13093 2.3934v-12.1401h4.4199v4.4985l4.1309-2.3646v-15.60039z' />
        <path d='m15.455 28 11.0351-6.3728v-15.25442l-4.1598-2.39341v15.25433l-6.8753 3.9794z' />
      </g>
    </svg>
  );
};
