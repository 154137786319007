import * as React from 'react';

export default ({
  width = 83,
  height = 30,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 720 261'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 201.62V0.0125977L107.398 0V25.3771H25.9411V80.3152H88.4868V105.692H25.9411V201.7L0 201.62ZM171.008 24.0199C171.008 33.3635 163.434 40.938 154.09 40.938C144.747 40.938 137.172 33.3635 137.172 24.0199C137.172 14.6763 144.747 7.10181 154.09 7.10181C163.434 7.10181 171.008 14.6763 171.008 24.0199ZM140.894 66.3149V201.66H166.835V66.3149H140.894ZM204.562 126.092C204.562 91.5015 228.602 62.3674 263.05 62.3674C278.821 62.3674 291.818 68.473 301.277 78.4128V66.3149H327.218V191.791C327.218 228.542 297.426 258.335 260.674 258.335C247.405 258.335 235.043 254.451 224.662 247.759L237.66 225.247C244.201 229.755 252.411 232.958 260.674 232.958C283.663 232.958 301.277 214.215 301.277 191.791V173.772C291.818 183.711 278.821 189.817 263.05 189.817C228.602 189.817 204.562 160.683 204.562 126.092ZM265.87 87.7445C246.927 87.7445 230.503 104.621 230.503 126.092C230.503 147.563 246.927 164.44 265.87 164.44C284.814 164.44 301.237 147.563 301.237 126.092C301.237 104.621 284.814 87.7445 265.87 87.7445ZM420.691 87.7445C405.352 87.7445 392.917 100.743 392.917 116.082V201.66H366.976V66.3149H392.917V78.7133C400.518 68.8876 411.716 62.3674 426.33 62.3674C444.507 62.3674 458.458 71.3957 466.538 85.2126C475.523 71.8667 490.179 62.3674 507.819 62.3674C537.344 62.3674 555.721 86.188 555.895 115.659V115.927V115.932V115.936V115.941V116.082V201.66H529.954V116.082C529.954 100.743 517.519 87.7445 502.18 87.7445C486.84 87.7445 474.406 100.743 474.406 116.082V201.66H448.465V116.082C448.465 100.743 436.03 87.7445 420.691 87.7445ZM648.38 62.3674C666.713 62.3674 682.518 69.7409 694.059 81.6575V66.3149H720V201.66H694.059V186.317C682.518 198.234 666.713 205.607 648.38 205.607C609.788 205.607 582.4 172.932 582.4 133.987C582.4 95.0423 609.788 62.3674 648.38 62.3674ZM651.2 87.7445C628.124 87.7445 608.341 108.15 608.341 133.987C608.341 159.825 628.124 180.23 651.2 180.23C674.275 180.23 694.059 159.825 694.059 133.987C694.059 108.15 674.275 87.7445 651.2 87.7445Z'
        fill='currentColor'
      />
    </svg>
  );
};
