import * as React from 'react';

import { ArrowDropDownIcon, Menu } from '@circleci/compass';
import { Popover } from '@circleci/compass/dist/rac/Popover';
import { MenuTrigger } from 'react-aria-components';
import { useIntl } from 'react-intl';

import { UseTranslatedHref } from '../shared/Link';
import { AuthButtonDropDownItem } from './AuthButtonGitHub';
import { Separator, DropdownButton, MenuLink } from './ButtonGroup';

interface AuthButtonDropDownProps {
  query: Map<string, string>;
  pageHref?: string;
  testId: string;
  firstItem: AuthButtonDropDownItem;
  secondItem: AuthButtonDropDownItem;
}

export const ButtonGroupDropdown: React.FC<AuthButtonDropDownProps> = ({
  query,
  pageHref,
  testId,
  firstItem,
  secondItem,
}) => {
  const intl = useIntl();
  return (
    <>
      <Separator orientation='vertical' />
      <MenuTrigger>
        <DropdownButton
          id={testId}
          data-testid={testId}
        >
          <ArrowDropDownIcon size={16} />
        </DropdownButton>
        <Popover crossOffset={-110}>
          <Menu data-testid={'auth-button-github-dropdown-menu'}>
            <MenuLink
              href={UseTranslatedHref(
                firstItem.hrefTranslation,
                pageHref,
                query,
              )}
              data-testid={firstItem.testId}
            >
              {intl.formatMessage({
                id: firstItem.textId,
              })}
            </MenuLink>
            <MenuLink
              href={UseTranslatedHref(
                secondItem.hrefTranslation,
                pageHref,
                query,
              )}
              data-testid={secondItem.testId}
            >
              {intl.formatMessage({
                id: secondItem.textId,
              })}
            </MenuLink>
          </Menu>
        </Popover>
      </MenuTrigger>
    </>
  );
};
