import * as React from 'react';

import { CircleLogoIcon, Flex, SmallHeading } from '@circleci/compass';
import { useIntl } from 'react-intl';

import { mediaMaxWidthMedium } from '~/components/shared/screenSizes';
import linkMessages from '~/links/messages';
import styled from '~/styled';

import { setQueryParam } from '../shared/Link';
import AuthButtonBitbucket from './AuthButtonBitbucket';
import AuthButtonSignup from './AuthButtonSignup';

interface DecoupledSignupCTAProps {
  titleBtns: string;
  signupBtn: string;
  secondaryBtn: string;
  'data-cy'?: string;
  page: 'signup' | 'vcsAuthorized';
  query: Map<string, string>;
}

const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .show-signup-form & {
    &.authwrapper-signup [data-optimizely='email-button'] {
      display: none;
    }
  }
`;

const ButtonsHeading = styled('h2')(({ theme }) => {
  return `
  color: ${theme.colors.cg900};
  font-size: ${theme.fontSizes.h4};
  line-height: 1.3;
  font-weight: ${theme.fontWeights.semiBold};
  margin: 0 0 0.5rem;
  ${mediaMaxWidthMedium} {
    text-align: left;
  }
`;
});

const StyledSmallHeading = styled(SmallHeading)(({ theme }) => {
  return `
  font-weight: ${theme.fontWeights.regular};
  margin: 0 0 2rem;
`;
});

const DecoupledSignupCTA = ({
  titleBtns,
  signupBtn,
  secondaryBtn,
  page,
  query,
  'data-cy': dataCy,
}: DecoupledSignupCTAProps): React.ReactElement => {
  const intl = useIntl();
  const bitbucketPath = intl.formatMessage(linkMessages.loginBitBucket);
  return (
    <AuthWrapper
      data-testid='decoupled-signup-cta'
      data-cy={dataCy}
      className={`authwrapper-${page}`}
    >
      <>
        <ButtonsHeading>
          {intl.formatMessage({
            id: titleBtns,
          })}
        </ButtonsHeading>
        <StyledSmallHeading color='n900'>
          {intl.formatMessage({
            id: 'signup.hero.ff_vcsSubheading',
          })}
        </StyledSmallHeading>
      </>
      <Flex
        direction={'column'}
        gap={'space16'}
        alignItems={'start'}
      >
        <AuthButtonSignup
          textId={signupBtn}
          query={query}
          Icon={CircleLogoIcon}
        />
        <AuthButtonBitbucket
          destination={setQueryParam(bitbucketPath, query)}
          textId={secondaryBtn}
          style='outlined'
        />
      </Flex>
    </AuthWrapper>
  );
};

export default DecoupledSignupCTA;
