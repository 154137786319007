import * as React from 'react';

import { useTrackAction } from '@circleci/analytics';
import { UseTrackActionProps } from '@circleci/analytics/build/src/hooks/useTrackAction';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import ContentWrapper from '~/components/ContentWrapper';
import Logo from '~/components/icons/Logo';
import * as colors from '~/components/shared/colors';
import Link, {
  IntlLink,
  EventData,
  getQueryParams,
} from '~/components/shared/Link';
import {
  mediaMaxWidthMedium,
  HideSmallerThanLarge,
  HideLargerThanLarge,
  mediaMaxWidthXtraSmall,
} from '~/components/shared/screenSizes';
import * as typography from '~/components/shared/typography';
import linkMessages from '~/links/messages';
import styled from '~/styled';

const Outer = styled.div(({ theme }) => {
  return `
    width: 100%;
    position: fixed;
    z-index: 3;
    font-size: ${typography.sizes.fs400};

    ${mediaMaxWidthMedium} {
      background: ${theme.colors.cg800};
    }
  `;
});

const Inner = styled.div(({ theme }) => {
  return `
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(
      to right,
      ${theme.colors.cg800} 62%,
      white 62%
    );
    justify-content: space-between;

    ${mediaMaxWidthMedium} {
      background-image: none;
      padding: 0 min(7vw, 90px);
    }
  `;
});

const LogoWrapper = styled.div`
  width: 105px;

  svg {
    color: white;
  }
`;

const BlackText = styled.span`
  color: ${colors.black100};
  -webkit-text-stroke: 0;
`;

const HideSmallerThanSmallSpan = styled.span`
  ${mediaMaxWidthXtraSmall} {
    display: none;
  }
`;

const WhiteText = styled.span`
  color: white;
  display: inline-block;
  -webkit-text-stroke: 0;
`;

type Pages = 'vcsAuthorize' | 'signup';

interface Link {
  hrefTranslation: any;
  event: UseTrackActionProps;
  pageHref?: string;
}

type LinkData = { [page in Pages]: Link };

const linkData: LinkData = {
  vcsAuthorize: {
    hrefTranslation: linkMessages.signup,
    event: {
      context: 'authentication',
      object: 'button',
      eventProperties: {
        button: 'signup',
      },
    },
    pageHref: '/signup/',
  },
  signup: {
    hrefTranslation: linkMessages.vcsAuthorize,
    event: {
      context: 'authentication',
      object: 'button',
      eventProperties: {
        button: 'login',
      },
    },
    pageHref: '/vcs-authorize/',
  },
};

const logoData: EventData<{}> = {
  name: 'click-outer-cta',
  props: {
    location: 'top nav',
    category: 'logo',
    cta_text: 'Home',
  },
};

export default ({ page }) => {
  const router = useRouter(),
    queryParams = getQueryParams((router && router.asPath) || ''),
    returnTo = queryParams.get('return-to'),
    query = new Map();

  const trackLink = useTrackAction(linkData[page].event);
  const omitHeaderLink = false;
  const intl = useIntl();
  if (returnTo) {
    query.set('return-to', returnTo);
  }
  return (
    <Outer data-cy='header'>
      <ContentWrapper>
        <Inner>
          <LogoWrapper>
            <IntlLink
              hrefTranslation={linkMessages.root}
              event={logoData}
              query={query}
            >
              <Logo />
            </IntlLink>
          </LogoWrapper>
          {!omitHeaderLink && (
            <>
              <HideSmallerThanLarge>
                <IntlLink
                  hrefTranslation={linkData[page].hrefTranslation}
                  trackLinkAction={trackLink}
                  pageHref={linkData[page].pageHref}
                  query={query}
                >
                  <BlackText
                    data-testid={`auth-${linkData[page].event.eventProperties.button}-button`}
                  >
                    {intl.formatMessage({
                      id: `${page}.header.linkText1`,
                    })}
                  </BlackText>
                  {intl.formatMessage({
                    id: `${page}.header.linkText2`,
                  })}
                </IntlLink>
              </HideSmallerThanLarge>
              <HideLargerThanLarge>
                <IntlLink
                  hrefTranslation={linkData[page].hrefTranslation}
                  trackLinkAction={trackLink}
                  pageHref={linkData[page].pageHref}
                  query={query}
                  data-testid={`auth-${linkData[page].event.eventProperties.button}-button`}
                >
                  <WhiteText
                    data-testid={`auth-${linkData[page].event.eventProperties.button}-button`}
                  >
                    <HideSmallerThanSmallSpan>
                      {intl.formatMessage({
                        id: `${page}.header.linkText1`,
                      })}
                    </HideSmallerThanSmallSpan>
                    {intl.formatMessage({
                      id: `${page}.header.linkText2`,
                    })}
                  </WhiteText>
                </IntlLink>
              </HideLargerThanLarge>
            </>
          )}
        </Inner>
      </ContentWrapper>
    </Outer>
  );
};
