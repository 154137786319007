import * as React from 'react';

import { useIntl } from 'react-intl';

import { IntlLink } from '~/components/shared/Link';
import linkMessages from '~/links/messages';

const RecaptchaTerms = () => {
  const i18nIdPrefix = 'recaptcha';
  const intl = useIntl();
  const locale = intl.locale;

  if (locale === 'ja') {
    return (
      <>
        {intl.formatMessage({
          id: 'ff_recaptcha.part1',
        })}
        <IntlLink hrefTranslation={linkMessages.recaptchaTerms}>
          {intl.formatMessage({
            id: 'ff_recaptcha.termsLinkText',
          })}
        </IntlLink>
        {intl.formatMessage({
          id: 'ff_recaptcha.part2',
        })}
      </>
    );
  }

  return (
    <>
      {intl.formatMessage({
        id: `${i18nIdPrefix}.part1`,
      })}
      <IntlLink hrefTranslation={linkMessages.recaptchaPrivacy}>
        {intl.formatMessage({
          id: `${i18nIdPrefix}.privacyLinkText`,
        })}
      </IntlLink>
      {intl.formatMessage({
        id: `${i18nIdPrefix}.part2`,
      })}
      <IntlLink hrefTranslation={linkMessages.recaptchaTerms}>
        {intl.formatMessage({
          id: `${i18nIdPrefix}.termsLinkText`,
        })}
      </IntlLink>
      {intl.formatMessage({
        id: `${i18nIdPrefix}.part3`,
      })}
    </>
  );
};

export default RecaptchaTerms;
