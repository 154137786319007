/* istanbul ignore file */
import * as React from 'react';

import * as snippet from '@segment/snippet';
import { extractCritical } from 'emotion-server';
import getConfig from 'next/config';
import { NEXT_DATA } from 'next/dist/shared/lib/utils';
import Document, {
  DocumentContext,
  DocumentProps,
  Head,
  Html,
  Main,
  NextScript,
} from 'next/document';

import generateCSP from '~/utils/generateCSP';
import generateNonce from '~/utils/generateNonce';
import '~/globalStyles';

type Props = DocumentProps & {
  css: any;
  query: any;
  ids?: any;
  nonce: any;
};

const NODE_ENV = process.env.NODE_ENV ?? 'development';

interface RuntimeConfig {
  hostname: string;
  isEnterprise?: boolean;
  analyticsKey: string;
  datadogRumApplicationId: string;
  datadogRumClientToken: string;
  mfeName: string;
}

export const assetPrefix: string =
  NODE_ENV === 'production'
    ? `https://assets-authentication.circleci.com/_next/static/${process.env.BUILD_ID}`
    : '';

export default class MyDocument extends Document<Props> {
  static async getInitialProps(ctx: DocumentContext) {
    const nonce = generateNonce();
    const csp = generateCSP(nonce, process.env.DATADOG_CLIENT_TOKEN || '');
    ctx.res?.setHeader('Content-Security-Policy', csp);
    const additionalProps = { nonce };

    const query = ctx.query;
    const page = (await ctx.renderPage()) as any;
    const styles = extractCritical(page.html);

    return { ...page, ...styles, query, ...additionalProps };
  }

  constructor(props: Props) {
    super(props);
    const { __NEXT_DATA__, ids } = props;
    if (ids) {
      (__NEXT_DATA__ as NEXT_DATA & { ids?: any }).ids = ids;
    }
  }

  renderSnippet(key: string | undefined) {
    if (!key) {
      return;
    }
    const opts = {
      apiKey: key,
      // note: the page option is disabled because it's limited to SSR
      // use: trackPage event to explicityle track page impressions
      page: false,
      load: false,
    };
    if (process.env.NODE_ENV === 'development') {
      return snippet.max(opts);
    }
    return snippet.min({
      host: 'cci-growth-utils.s3.us-east-2.amazonaws.com/segment',
      ...opts,
    });
  }

  renderLoadSegment(key: string | undefined) {
    return key
      ? `window.analytics.load('${key}', {
      integrations: {
        All: false,
        Amplitude: true,
        Postgres: true,
        "Segment.io": true,
        Sprig: true
      },
    });`
      : '';
  }

  loadCircleciConfigurationJavascript = ({
    hostname,
    isEnterprise = false,
    datadogRumApplicationId,
    datadogRumClientToken,
    mfeName,
    analyticsKey,
  }: RuntimeConfig): string => {
    const config = {
      hostname,
      isEnterprise,
      datadogRumApplicationId,
      datadogRumClientToken,
      mfeName,
      analyticsKey,
    };

    return `var circleci = {config: ${JSON.stringify(config)}};`;
  };

  renderGTMHeadSnippet(nonce): JSX.Element {
    const scriptTag = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
    n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W9HDVK');`;

    return (
      <script
        nonce={nonce}
        data-cookieconsent='marketing'
        type='text/plain'
        dangerouslySetInnerHTML={{ __html: scriptTag }}
      />
    );
  }

  renderGTMBodySnippet(): JSX.Element {
    const noScriptTag = `<iframe src="//www.googletagmanager.com/ns.html?id=GTM-W9HDVK" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    return <noscript dangerouslySetInnerHTML={{ __html: noScriptTag }} />;
  }

  render(): JSX.Element {
    const { publicRuntimeConfig } = getConfig();
    const {
      hostname,
      isEnterprise,
      datadogRumApplicationId,
      datadogRumClientToken,
      mfeName,
      analyticsKey,
    } = publicRuntimeConfig;

    const { nonce } = this.props;

    return (
      <Html lang='en-US'>
        <Head nonce={nonce}>
          <script
            nonce={nonce}
            id='Cookiebot'
            src='https://consent.cookiebot.com/uc.js'
            data-cbid='a28b71f3-4d2e-4eac-93bc-34929948dd7d'
            type='text/javascript'
            async
          ></script>
          <script
            nonce={nonce}
            type='text/javascript'
            dangerouslySetInnerHTML={{
              __html: this.loadCircleciConfigurationJavascript({
                hostname,
                isEnterprise,
                datadogRumApplicationId,
                datadogRumClientToken,
                mfeName,
                analyticsKey,
              }),
            }}
          />
          {this.renderGTMHeadSnippet(nonce)}
          <style dangerouslySetInnerHTML={{ __html: this.props.css }} />
          {/* Inject the Segment snippet into the <head> of the document  */}
          <script
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: this.renderSnippet(analyticsKey),
            }}
          />
          <script
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: this.renderLoadSegment(analyticsKey),
            }}
          />
          {/* Inject Optimizely then Segment snippet per this segment integration documentation: https://segment.com/docs/destinations/optimizely-web/#optimizely-x-web  */}
          <script
            nonce={nonce}
            data-cookieconsent='statistics'
            type='text/plain'
            src='https://cdn.optimizely.com/js/8188917468.js'
          />
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href={`${assetPrefix}/static/favicons/apple-touch-icon.png`}
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href={`${assetPrefix}/static/favicons/favicon-32x32.png`}
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href={`${assetPrefix}/static/favicons/favicon-16x16.png`}
          />
          <link
            rel='manifest'
            href={`${assetPrefix}/static/favicons/site.webmanifest`}
          />
          <link
            rel='mask-icon'
            href={`${assetPrefix}/static/favicons/safari-pinned-tab.svg`}
            color='#04aa51'
          />

          <link
            rel='preconnect'
            href='https://fonts.googleapis.com'
          />
          <link
            rel='preconnect'
            href='https://fonts.gstatic.com'
            crossOrigin='anonymous'
          />
          <link
            href='https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Space+Grotesk:wght@300..700&display=swap'
            rel='stylesheet'
          />
          <meta
            name='msapplication-TileColor'
            content='#603cba'
          />
          <meta
            name='theme-color'
            content='#ffffff'
          />
        </Head>
        <body>
          {this.renderGTMBodySnippet()}
          <Main />
          <NextScript nonce={nonce} />
        </body>
      </Html>
    );
  }
}
