import * as React from 'react';

import { useIntl } from 'react-intl';

import * as colors from '~/components/shared/colors';
import { IntlLink } from '~/components/shared/Link';
import RecaptchaTerms from '~/components/shared/RecaptchaTerms';
import * as screenSizes from '~/components/shared/screenSizes';
import * as fonts from '~/components/shared/typography';
import linkMessages from '~/links/messages';
import styled from '~/styled';

const ParagraphSmall = styled.p`
  line-height: 1.75;
  font-size: ${fonts.sizes.fs400};
  color: ${colors.black100};
  margin-top: 20px;

  ${screenSizes.mediaMaxWidthXtraSmall} {
    text-align: left;
  }

  ${screenSizes.mediaMaxWidthSmall} {
    font-weight: 400;
  }

  .shorter-version {
    display: none;
  }

  .show-signup-form .authwrapper-signup + & {
    margin-top: 40px;

    .longer-version {
      display: none;
    }

    .shorter-version {
      display: inline;
    }
  }
`;

const ParagraphDecoupled = styled(ParagraphSmall)(({ theme }) => {
  return `
  margin: ${theme.space.space48} 0 0;
`;
});

const Disclaimer = ({ page }: { page: string }): React.ReactElement => {
  const intl = useIntl();
  const locale = intl.locale;

  if (locale === 'ko' && page === 'signup') {
    return (
      <ParagraphDecoupled data-testid='disclaimer-decoupled'>
        {intl.formatMessage({
          id: 'ff_disclaimer.section1.part1',
        })}
        <IntlLink hrefTranslation={linkMessages.termsOfService}>
          {intl.formatMessage({
            id: 'ff_disclaimer.section1.link1Text',
          })}
        </IntlLink>
        {intl.formatMessage({
          id: 'ff_disclaimer.section1.part2',
        })}
        <IntlLink hrefTranslation={linkMessages.privacyPolicy}>
          {intl.formatMessage({
            id: 'ff_disclaimer.section1.link2Text',
          })}
        </IntlLink>
        {intl.formatMessage({
          id: 'ff_disclaimer.section1.part3',
        })}
        {intl.formatMessage({
          id: 'ff_disclaimer.section2.part1',
        })}
        {intl.formatMessage({
          id: 'ff_disclaimer.section3.part1',
        })}
        <IntlLink hrefTranslation={linkMessages.privacyPolicy}>
          {intl.formatMessage({
            id: 'ff_disclaimer.section3.link1Text',
          })}
        </IntlLink>
        {intl.formatMessage({
          id: 'ff_disclaimer.section3.part2',
        })}
        <IntlLink hrefTranslation={linkMessages.recaptchaTerms}>
          {intl.formatMessage({
            id: 'ff_disclaimer.section3.link2Text',
          })}
        </IntlLink>
        {intl.formatMessage({
          id: 'ff_disclaimer.section3.part3',
        })}
      </ParagraphDecoupled>
    );
  }
  if (locale === 'en') {
    return (
      <ParagraphDecoupled data-testid='disclaimer-decoupled'>
        {intl.formatMessage({
          id: `disclaimer.part1.${page}`,
        })}{' '}
        <IntlLink hrefTranslation={linkMessages.termsOfService}>
          {intl.formatMessage({
            id: 'ff_disclaimer.link1Text',
          })}
        </IntlLink>{' '}
        {intl.formatMessage({
          id: 'ff_disclaimer.part2',
        })}{' '}
        <IntlLink hrefTranslation={linkMessages.privacyPolicy}>
          {intl.formatMessage({
            id: 'ff_disclaimer.link2Text',
          })}
        </IntlLink>
        .
        <RecaptchaTerms />
      </ParagraphDecoupled>
    );
  }
  if (page === 'vcsAuthorized' && locale === 'ja') {
    return (
      <ParagraphDecoupled data-testid='disclaimer-decoupled'>
        {intl.formatMessage({
          id: `disclaimer.part1.${page}`,
        })}{' '}
        <IntlLink hrefTranslation={linkMessages.termsOfService}>
          {intl.formatMessage({
            id: 'disclaimer.link1Text',
          })}
        </IntlLink>{' '}
        {intl.formatMessage({
          id: 'disclaimer.part2',
        })}{' '}
        <IntlLink hrefTranslation={linkMessages.privacyPolicy}>
          {intl.formatMessage({
            id: 'disclaimer.link2Text',
          })}
        </IntlLink>
        {intl.formatMessage({
          id: `disclaimer.part3`,
        })}
        <RecaptchaTerms />
      </ParagraphDecoupled>
    );
  }
  if (page === 'signup' && locale === 'ja') {
    return (
      <ParagraphDecoupled data-testid='disclaimer-decoupled'>
        {intl.formatMessage({
          id: `ff_disclaimer.part1.${page}`,
        })}{' '}
        <IntlLink hrefTranslation={linkMessages.termsOfService}>
          {intl.formatMessage({
            id: 'ff_disclaimer.link1Text',
          })}
        </IntlLink>{' '}
        {intl.formatMessage({
          id: 'ff_disclaimer.part2',
        })}{' '}
        <IntlLink hrefTranslation={linkMessages.privacyPolicy}>
          {intl.formatMessage({
            id: 'ff_disclaimer.link2Text',
          })}
        </IntlLink>
        {intl.formatMessage({
          id: `ff_disclaimer.part3`,
        })}
        <RecaptchaTerms />
      </ParagraphDecoupled>
    );
  }

  return (
    <ParagraphSmall data-testid='disclaimer-legacy'>
      {intl.formatMessage({
        id: `disclaimer.part1.${page}`,
      })}{' '}
      <IntlLink hrefTranslation={linkMessages.termsOfService}>
        {intl.formatMessage({
          id: 'disclaimer.link1Text',
        })}
      </IntlLink>{' '}
      {intl.formatMessage({
        id: 'disclaimer.part2',
      })}{' '}
      <IntlLink hrefTranslation={linkMessages.privacyPolicy}>
        {intl.formatMessage({
          id: 'disclaimer.link2Text',
        })}
      </IntlLink>
      <span className='longer-version'>
        {intl.formatMessage({
          id: 'disclaimer.part3',
        })}
      </span>
      <span className='shorter-version'>
        {intl.formatMessage({
          id: 'disclaimer.part3Shortened',
        })}
      </span>
      <RecaptchaTerms />
    </ParagraphSmall>
  );
};

export default Disclaimer;
